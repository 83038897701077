/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function () {
        // JavaScript to be fired on all pages

        $("#back_btn").click(function (e) {
          e.preventDefault;
          history.go(-1);
        });
        /*----------------------------------------------
        	H I D E   P A G E   L O A D E R  + S M O O T H   S H O W
        ------------------------------------------------*/

        $("#page-loader .page-loader-inner")
          .delay(500)
          .fadeIn(10, function () {
            $(this).fadeOut(500, function () {
              $("#page-loader").fadeOut(500);
            });
          });

        var wow = new WOW({
          boxClass: "wow", // animated element css class (default is wow)
          animateClass: "animated", // animation css class (default is animated)
          offset: 0, // distance to the element when triggering the animation (default is 0)
          mobile: true, // trigger animations on mobile devices (default is true)
          live: true, // act on asynchronously loaded content (default is true)
          callback: function (box) {
            // the callback is fired every time an animation is started
            // the argument that is passed in is the DOM node being animated
          },
          scrollContainer: null, // optional scroll container selector, otherwise use window
        });
        wow.init();
        // Autosize
        autosize($("textarea"));

        var search = $("#searchform");

        // Open serach

        $(".open-search")
          .find("a")
          .click(function (e) {
            e.preventDefault;
            var tl = new TimelineLite();
            tl.to(search, 0.4, { x: "0%", display: "block" });
            $("#s").focus();
          });

        // Close search

        $("#close-search").click(function () {
          var tl = new TimelineLite();
          tl.to(search, 0.4, { x: "-100%", display: "none" });
          $("#s").focusout();
        });

        // Navigation

        var fissa = 0;
        var header = $(".header");

        function toggleNavbar() {
          if ($(window).scrollTop() > 350) {
            if (fissa == 0) {
              fissa = 1;
              $(header)
                .addClass("header--fixed")
                .css({
                  opacity: 0,
                  top: -30,
                })
                .animate(
                  {
                    opacity: 1,
                    top: 0,
                  },
                  400,
                  function () {}
                );
            }
          }
          if ($(window).scrollTop() < 350) {
            if (fissa == 1) {
              fissa = 0;
              $(header).animate(
                {
                  opacity: 0,
                  top: -30,
                },
                200,
                function () {
                  $(header).removeClass("header--fixed").animate(
                    {
                      opacity: 1,
                      top: 0,
                    },
                    400
                  );
                }
              );
            }
          }
        }

        $(window).scroll(function () {
          if ($(window).width() >= 768) {
            toggleNavbar();
          }
        });

        // init Isotope
        var $grid = $(".grid");
        $grid.imagesLoaded(function () {
          var $grid = $(".grid").isotope({
            itemSelector: ".element-item",
            layoutMode: "fitRows",
          });
        });
        // filter functions
        var filterFns = {
          // show if number is greater than 50
          numberGreaterThan50: function (i, elem) {
            var number = $(elem).find(".number").text();
            return parseInt(number, 10) > 50;
          },
          // show if name ends with -ium
          ium: function (i, elem) {
            var name = $(elem).find(".name").text();
            return name.match(/ium$/);
          },
        };
        // bind filter button click
        $(".filters-button-group").on("click", "button", function () {
          var filterValue = $(this).attr("data-filter");
          console.log(filterValue.slice(1));
          console.log(
            "test---->",
            $(".filter-group").find(".is-checked").attr("data-url")
          );
          localStorage.setItem(
            "url-back",
            $(".filter-group").find(".is-checked").attr("data-url") +
              "#" +
              filterValue.slice(1)
          );

          // use filterFn if matches value
          filterValue = filterFns[filterValue] || filterValue;
          $grid.isotope({ filter: filterValue });
        });
        // change is-checked class on buttons
        $(".filter-group").each(function (i, buttonGroup) {
          var $buttonGroup = $(buttonGroup);
          $buttonGroup.on("click", "button", function () {
            $buttonGroup.find(".is-checked").removeClass("is-checked");
            $(this).addClass("is-checked");
          });
        });

        // if ($(".is-checked").length) {
        //   localStorage.setItem("filter", $(".is-checked").attr("data-filter"));
        // }

        // if (!$(".single-projet")) {
        //   localStorage.setItem("filter", "");
        //   localStorage.setItem("subfilter", "");
        // }

        if (!$(".single-projet")) {
        }

        if ($(".page-template-nos-besoins").length) {
          localStorage.setItem(
            "url-back",
            $(".filter-group").find(".is-checked").attr("data-url")
          );

          var hash = window.location.hash.substr(1);
          var elementSelected = $(".filter-main").find(
            "[data-filter='." + hash + "']"
          );
          console.log("test 2", elementSelected);
          elementSelected.click();
          setTimeout(function () {
            elementSelected.click();
          }, 300);
        }

        if ($(".page-template-discover").length) {
          localStorage.setItem(
            "url-back",
            $(".filter-group").find(".is-checked").attr("data-url")
          );

          var hash = window.location.hash.substr(1);
          console.log("test 2", hash);
          var elementSelected = $(".subfilter").find(
            "[data-filter='." + hash + "']"
          );

          setTimeout(function () {
            elementSelected.click();
          }, 300);
        }
        if ($(".single-projet").length) {
          if (localStorage.getItem("url-back") !== "") {
            $("#back_btn").attr("href", localStorage.getItem("url-back"));
            localStorage.setItem("url-back", "");
          }
          console.log("tatta");
          $(".filter-main").find(".is-checked").click();

          $(".subfilter").find(".default-active").addClass("is-checked");
          // }
        }

        $(".projet-template-default")
          .find($(".element-link"))
          .click(function () {
            localStorage.setItem("url-back", window.location.href);
          });

        $(".filter-main").on("click", "button", function () {
          var filterValue = $(this).attr("data-filter");
          // localStorage.setItem("filter", filterValue);
        });

        $(".subfilter").on("click", "button", function () {
          var filterValue = $(this).attr("data-filter");
          // localStorage.setItem("subfilter", filterValue);
        });

        // Fixed social on scroll
        if ($("#share").length) {
          var elementPosition = $("#share").offset();

          $(window).scroll(function () {
            if ($(window).width() >= 768) {
              if ($(window).scrollTop() + 100 > elementPosition.top) {
                $("#share").css("position", "fixed").css("top", "100px");
              } else {
                $("#share").css("position", "absolute").css("top", "764px");
              }
            }
          });
        }

        // Projet
        // if ($(".single-projet").length) {
        //   // var filterSelect = localStorage.getItem("filter");
        //   // var subfilterSelect = localStorage.getItem("subfilter");
        //   console.log("projet 2");

        //   var elementSelected = $(".filter-main").find(
        //     "[data-filter='" + filterSelect + "']"
        //   );
        //   var subElementSelected = $(".subfilter").find(
        //     "[data-filter='" + subfilterSelect + "']"
        //   );

        //   $(elementSelected).click();
        //   setTimeout(function () {
        //     $(elementSelected).click();
        //   }, 500);
        //   $(subElementSelected).click();
        // }
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      },
    },
    // Home page
    home: {
      init: function () {
        // JavaScript to be fired on the home page
        // Full Page
        $("#fullpage").fullpage({
          css3: true,
          navigation: true,
          lockAnchors: false,
          anchors: [
            "section-1",
            "section-2",
            "section-3",
            "section-4",
            "section-5",
            "section-6",
            "section-7",
          ],
          navigationPosition: "right",
          scrollingSpeed: 900,
          autoScrolling: true,
          fitToSection: true,
          fitToSectionDelay: 1000,
          scrollBar: false,
          easing: "easeInOutCubic",
          easingcss3: "ease",
          loopBottom: true,
          loopTop: false,
          continuousVertical: false,
          touchSensitivity: 15,
          afterLoad: function (anchorLink, index) {
            if (index == 2) {
              $(".section__presentation").addClass("active");
            }
            if (index == 3) {
              $(".section__presentation").addClass("active");
            }
            if (index == 4) {
              $(".section__presentation").addClass("active");
            }
            if (index == 5) {
              $(".section__presentation").addClass("active");
            }
            if (index == 6) {
              $(".section__presentation").addClass("active");
            }
            if (index == 7) {
              $(".section__presentation").addClass("active");
            }
          },

          onLeave: function (index, nextIndex, direction) {
            if (index == 2) {
              $(".section__presentation").toggleClass("active");
            }
            if (index == 3) {
              $(".section__presentation").toggleClass("active");
            }
            if (index == 4) {
              $(".section__presentation").toggleClass("active");
            }
            if (index == 5) {
              $(".section__presentation").toggleClass("active");
            }
            if (index == 6) {
              $(".section__presentation").toggleClass("active");
            }
            if (index == 7) {
              $(".section__presentation").toggleClass("active");
            }
          },
        });
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      },
    },
    // About page
    about: {
      init: function () {
        // JavaScript to be fired on the about us page
        // var InstagramToken = InstagramToken;
        var waypoints = $(".about__chiffres").waypoint({
          handler: function (direction) {
            console.log("ttatatattata");
            $(".count").each(function () {
              $(this)
                .fadeTo("fast", 1)
                .prop("Counter", 0)
                .animate(
                  {
                    Counter: $(this).text(),
                  },
                  {
                    duration: 1000,
                    easing: "swing",
                    step: function (now) {
                      $(this).text(Math.ceil(now));
                    },
                  }
                );
            });
            this.destroy();
          },
          offset: 400,
        });

        console.log("InstagramToken", InstagramToken);
        // "IGQVJXc0k2NEFuQ05abS05U19oX0Y5UEpHeUpRVnJjaXVyUjhHVnVxUXgtOVN6MHBjR3ZAXZAmJhMnBHYTQ1a2JRb3lkQmdpQml0dmdLamNSci1KNnZAKaVVnYVRuYkF3RktwX1BIY3FR";
        var feed = new Instafeed({
          accessToken: InstagramToken,
          limit: 5,
        });
        feed.run();

        // var feed = new Instafeed({
        //   get: "user",
        //   userId: "17841403125991254",
        //   accessToken: "6sdgqiijob6e3twub4u6pk",

        //   //userId: '1228922708',
        //   //accessToken: '1228922708.871bc07.4ed8b711c9e54f589b1603ce69522fe7',
        //   resolution: "low_resolution",
        //   limit: 10,
        //   filter: function (image) {
        //     return image;
        //   },
        // });
        // feed.run();
      },
    },

    // Contact page-specific
    contact: {
      init: function () {
        // When the window has finished loading create our google map below
        google.maps.event.addDomListener(window, "load", init);

        function init() {
          // Basic options for a simple Google Map
          // For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
          var mapOptions = {
            // How zoomed in you want the map to start at (always required)
            zoom: 14,
            scrollwheel: false,
            navigationControl: false,
            mapTypeControl: false,
            // The latitude and longitude to center the map (always required)
            center: new google.maps.LatLng(48.9168253, 2.2526898), // New York

            // How you would like to style the map.
            // This is where you would paste any style found on Snazzy Maps.
            styles: [
              {
                featureType: "landscape",
                stylers: [
                  { saturation: -100 },
                  { lightness: 65 },
                  { visibility: "on" },
                ],
              },
              {
                featureType: "poi",
                stylers: [
                  { saturation: -100 },
                  { lightness: 51 },
                  { visibility: "simplified" },
                ],
              },
              {
                featureType: "road.highway",
                stylers: [{ saturation: -100 }, { visibility: "simplified" }],
              },
              {
                featureType: "road.arterial",
                stylers: [
                  { saturation: -100 },
                  { lightness: 30 },
                  { visibility: "on" },
                ],
              },
              {
                featureType: "road.local",
                stylers: [
                  { saturation: -100 },
                  { lightness: 40 },
                  { visibility: "on" },
                ],
              },
              {
                featureType: "transit",
                stylers: [{ saturation: -100 }, { visibility: "simplified" }],
              },
              {
                featureType: "administrative.province",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "water",
                elementType: "labels",
                stylers: [
                  { visibility: "on" },
                  { lightness: -25 },
                  { saturation: -100 },
                ],
              },
              {
                featureType: "water",
                elementType: "geometry",
                stylers: [
                  { hue: "#ffff00" },
                  { lightness: -25 },
                  { saturation: -97 },
                ],
              },
            ],
          };

          // Get the HTML DOM element that will contain your map
          // We are using a div with id="map" seen below in the <body>
          var mapElement = document.getElementById("map");

          // Create the Google Map using our element and options defined above
          var map = new google.maps.Map(mapElement, mapOptions);

          // Let's also add a marker while we're at it
          var marker = new google.maps.Marker({
            position: new google.maps.LatLng(48.9168253, 2.2526898),
            map: map,
            title: "Creashow!",
            icon: templateUrl + "/assets/images/pin.svg",
          });
        }
      },
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, "_").split(/\s+/), function (
        i,
        classnm
      ) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, "finalize");
      });

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    },
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
